(function ($) {

Drupal.behaviors.hashRedirects = {
  attach: function(context, settings) {

    if ($('body').hasClass('device-mobile')) {
      var loc           = window.location,
          url           = loc.href,
          path          = loc.pathname.substring(1),
          hash          = '',
          redirect_path = '';

      if (url.indexOf('#') != -1) {
        hash = url.substring(parseInt(url.indexOf('#')) + 1);
      }

      if (path == 'makeup-taaz') {
        redirect_path = hash == 'falltrendlooks_pinks'         ? 'get-the-look/falltrendlooks_pinks' :
                        hash == 'falltrendlooks_coralstawnies' ? 'get-the-look/falltrendlooks_coralstawnies' :
                        hash == 'falltrendlooks_violets'       ? 'get-the-look/falltrendlooks_violets' :
                                                                  '';
      } else if (path == 'kiss-the-bride') {
        redirect_path = hash == 'beach' ? 'kiss-the-bride/beach-wedding' :
                        hash == 'city'  ? 'kiss-the-bride/city-wedding' :
                                          '';
      }

      if (redirect_path.length > 0) {
        window.location.href = settings.basePath + settings.pathPrefix + redirect_path;
      }
    }

  }
};

})(jQuery);
